import { FunctionComponent, useEffect, useState } from "react";
import { useCustomer } from "../../common/contexts/CustomerContext";
import React from "react";
import { useDroneEvents, useStartedDroneEvents } from '@murphy-frontend/common/api/DroneEvents/Queries';
import { useCompleteEvent, useStartEvent } from '@murphy-frontend/common/api/DroneEvents/Mutations';
import Button from "@murphy-frontend/web-core/components/Button";
import StartedEventFlowsTable from "./components/StartedEventFlowsTable";
import { MurphyDroneEventDto } from "@murphy-frontend/common/interfaces/IDroneEventsApi";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useUserPreferences } from "../../common/contexts/UserPreferencesContext";
import FilterControl, { FilterType } from "@murphy-frontend/web-core/components/FilterControl";
import SearchBar from "@murphy-frontend/web-core/components/SearchBar";
import { StartEventDto } from "@murphy-frontend/common/api/DroneEvents/models";

interface EventFlowsProps {

}

const EventFlows: FunctionComponent<EventFlowsProps> = () => {

    const { customer } = useCustomer();
    const { data: startedEvents, isLoading: startedEventsIsLoading } = useStartedDroneEvents(customer?.Id, true);
    const { data: eventTemplates, isLoading: eventTemplatesIsLoading } = useDroneEvents(customer?.Id);
    const { mutate: startEvent, isPending: startEventIsPending } = useStartEvent(customer?.Id);
    const { mutate: compeleteEvent, isPending: completeEventIsPending } = useCompleteEvent(customer?.Id);

    const [filters, setFilters] = useState<FilterType[]>([]);
    const [markAllIsToggled, setMarkAllIsToggled] = useState<boolean>(true);

    const [filteredStartedEvents, setFilteredStartedEvents] = useState([]);
    const [currentSearchText, setCurrentSearchText] = useState('');

    const { timeZone, translations } = useUserPreferences();

    const { currentSize } = useWindowDimensions();
    const navigate = useNavigate();

    const onClickStartedEvent = (dto: MurphyDroneEventDto) => {
        const url = `/startedeventflows/${dto.Id}`;
        navigate(url);
    };

    const onClickNew = () => {
        // get last event template
        const eventTemplateId = eventTemplates?.[0]?.Id;

        const startEventDto: StartEventDto = {
            EventFlowTemplateId: eventTemplateId,
            GeoLocation: {
                GeoX: '13.049378067221362',
                GeoY: '55.5808510100517',
                DeniedGeo: false,
            }
        };

        startEvent(startEventDto);
        // navigate('/startedeventflows/new');
    }

    const onClickCloseStartedEvent = (dto: MurphyDroneEventDto) => {
        compeleteEvent(dto.Id);
    }

    const onClickLocation = (dto: MurphyDroneEventDto) => {
        window.open("https://maps.google.com?q=" + dto.Latitude + "," + dto.Longitude);
    }

    const onToggleMarkAllFilters = () => {
        const newAllRows = filters.map((p) => {
            return { ...p, isActive: !markAllIsToggled };
        });

        setFilters(newAllRows);
        setMarkAllIsToggled(!markAllIsToggled);
    };

    const onToggleFilter = (filt: FilterType) => {
        const updatedFilters = filters.map((filter) => {
            const updatedItem = { ...filter };
            if (
                filter.columnname === filt.columnname &&
                filter.filtervalue === filt.filtervalue
            ) {
                updatedItem.isActive = !filter.isActive;
            }
            return updatedItem;
        });

        if (updatedFilters.every((p) => p.isActive)) {
            setMarkAllIsToggled(true);
        } else {
            setMarkAllIsToggled(false);
        }
        setFilters(updatedFilters);
    };


    const onResetFilters = () => {
        const initFilters = generateInitialFilters();
        setFilters(initFilters);
    };

    const generateInitialFilters = (): Array<FilterType> => {
        const allPossibleFilters = [
            {
                displayName: "Open",
                columnname: "Open",
                filtervalue: true,
                isActive: true,
            },
            {
                displayName: "Closed",
                columnname: "Open",
                filtervalue: false,
                isActive: false,
            },
        ];
        return allPossibleFilters;
    };

    const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setCurrentSearchText(newValue);
    };

    useEffect(() => {
        if (translations) {
            const initFilters = generateInitialFilters();
            setFilters(initFilters);
        }
    }, [translations]);

    useEffect(() => {

        if (!startedEvents) {
            return;
        }

        let newFilteredList: Array<MurphyDroneEventDto> = [];

        for (let i = 0; i < filters.length; i++) {
            const currentFilter = filters[i];
            if (currentFilter.isActive === true) {
                const currentFilterMatches = startedEvents.filter((recipient: any) => {
                    if (recipient[currentFilter.columnname] === currentFilter.filtervalue) {
                        return recipient;
                    }
                });
                newFilteredList = newFilteredList.concat(currentFilterMatches);
            }
        }

        if (currentSearchText) {
            const searchValue = currentSearchText.toUpperCase();
            newFilteredList = newFilteredList.filter((p) =>
                p.Name.toUpperCase().startsWith(searchValue),
            );
        }
        setFilteredStartedEvents(newFilteredList);
    }, [startedEvents, filters, currentSearchText]);

    if (startedEventsIsLoading || startEventIsPending || eventTemplatesIsLoading || completeEventIsPending) {
        return <Spinner isGlobal />;
    }

    return (<div className="generalwrapper">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>
                    {translations?.startedeventflows?.toUpperCase()}
                </h4>

            </div>
            <div className="two">
                <Button
                    isIconButton
                    buttonStyle="btn--add"
                    buttonSize="btn-with-icon"
                    onClick={onClickNew}
                >
                    <span className="btn-with-icon-text">
                        Start new event flow
                    </span>
                </Button>
            </div>
        </section>
        <section className="user-table-container">
            <div className="space-between-flex">
                <div className="filter-control-wrapper">
                    <FilterControl
                        allFilters={filters}
                        onToggleFilter={onToggleFilter}
                        showReset={false}
                        onClickResetFilters={onResetFilters}
                        translations={translations}
                        showMarkAll={true}
                        onToggleMarkAll={onToggleMarkAllFilters}
                        markAllIsToggled={markAllIsToggled}
                    />
                </div>
                <div>
                    <SearchBar
                        val={currentSearchText}
                        handleChange={onSearchChanged}
                        placeholder={
                            translations['placeholder-lang-search-action']
                        }
                    />
                </div>
            </div>

            <StartedEventFlowsTable
                startedEventFlows={filteredStartedEvents ?? []}
                onClick={onClickStartedEvent}
                onClickClose={onClickCloseStartedEvent}
                onClickLocation={onClickLocation}
                currentScreenSize={currentSize}
                timeZone={timeZone}
            />
        </section>
    </div>);
}

export default EventFlows;