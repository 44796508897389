import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import {
  IDroneEventsApi,
  MurphyDroneEventDto,
  MurphyOpenedDroneEventDto,
  MurphyPossibleDroneEventDto,
} from "../../interfaces/IDroneEventsApi";
import { StartEventDto } from "./models";

@injectable()
export class DroneEventsApi implements IDroneEventsApi {
  constructor(
    @inject(ApiServiceType.IApiService) private apiService: IApiService
  ) { }

  getStartedEvents = async (
    customerId: string,
    includeClosed?: boolean
  ): Promise<MurphyDroneEventDto[]> => {
    const baseUrl = `/api/EventFlow/GetStartedFlows/${customerId}`;
    const searchParams = new URLSearchParams();

    if (includeClosed) {
      searchParams.append('includeClosed', includeClosed.toString());
    }
    const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
    const url = `${baseUrl}${searchParamsString}`;

    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getEvents = async (
    customerId: string
  ): Promise<MurphyPossibleDroneEventDto[]> => {
    const url = `/api/EventFlow/GetEventFlows/${customerId}`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getEvent = async (eventId): Promise<MurphyOpenedDroneEventDto> => {
    const url = `/api/EventFlow/EventFlow/${eventId}`;
    const { data } = await this.apiService.callApi(url, "GET");
    if (data) {
      const parsedData = JSON.parse(data);
      return parsedData;
    }
  };

  startEvent = async (startEventDto: StartEventDto): Promise<number> => {
    const url = `/api/EventFlow/StartEventFlow`;
    const { data } = await this.apiService.callApi(url, "POST", startEventDto);
    return data;
  };

  saveFieldInEvent = async ({
    droneId,
    fieldId,
    updateValue,
  }: {
    droneId: number;
    fieldId: number;
    updateValue: boolean | string;
  }): Promise<void> => {
    const url = `/api/EventFlow/UpdateComponent`;
    const body = {
      DroneId: droneId,
      FieldId: fieldId,
      UpdateValue: updateValue,
    };
    const { data } = await this.apiService.callApi(url, "POST", body);
    return data;
  };

  completeEvent = async ({ droneId }: { droneId: number }): Promise<void> => {
    const url = `/api/EventFlow/CompleteEvent/${droneId}`;
    const { data } = await this.apiService.callApi(url, "POST");
    return data;
  };
}
