/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

function MBSResponseRow({
  id, userName, date, responseSelectedAlternative, responseAnswer, handleClick,
  translations,
}) {
  let baseClasses = 'item item-container item-container-configuration-pbsresponse';
  if (!date) {
    baseClasses += ' response-row-negative';
  } else {
    baseClasses += ' response-row-positive';
  }

  return (
    <li key={id} className={baseClasses} onClick={handleClick} onKeyUp={handleClick}>
      <div className="attribute" data-name={translations.answerdate} title={date || translations.noanswer}>{date || translations.noanswer}</div>
      <div className="attribute" data-name={translations.user} title={translations.user}>{userName}</div>
      <div className="attribute" data-name={translations.answeralternatives} title={responseSelectedAlternative}>{responseSelectedAlternative}</div>
      <div className="attribute" data-name={translations.freetextanswer} title={responseAnswer}>{responseAnswer}</div>
    </li>
  );
}

MBSResponseRow.propTypes = {
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  date: PropTypes.string,
  responseSelectedAlternative: PropTypes.string,
  responseAnswer: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

MBSResponseRow.defaultProps = {
  date: '',
  responseSelectedAlternative: '',
  responseAnswer: '',
};

export default MBSResponseRow;
