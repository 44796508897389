import { StartEventDto } from "../api/DroneEvents/models";
import { Drone } from "../api/DroneEvents/types";

export interface MurphyDroneEventDto {
  Id: number;
  Name: string;
  CreatedUTC: string;
  Open: boolean;
  CreatedByUserEmail: string;
  Longitude?: string;
  Latitude?: string;
}

export interface MurphyPossibleDroneEventDto {
  Id: number;
  Name: string;
  Description: string;
  Type: 1 | 2 | 3 | 4;
  GeoLocationEnabled: boolean;
  ConfirmBeforeStart: boolean;
}

export type MurphyOpenedDroneEventDto = Drone & {
  Tabs: string;
};

export interface IDroneEventsApi {
  getStartedEvents: (customerId: string, includeClosed?: boolean) => Promise<MurphyDroneEventDto[]>;
  getEvents: (customerId: string) => Promise<MurphyPossibleDroneEventDto[]>;
  getEvent: (eventId: number) => Promise<MurphyOpenedDroneEventDto>;
  startEvent: (startEventDto: StartEventDto) => Promise<number>;
  saveFieldInEvent: (props: {
    droneId: number;
    fieldId: number;
    updateValue: boolean | string;
  }) => Promise<void>;
  completeEvent: (props: { droneId: number }) => Promise<void>;
}

const DroneEventsApiType = {
  IDroneEventsApi: Symbol("IDroneEventsApi"),
};

export default DroneEventsApiType;
