import React, { createContext, useState, useEffect, useContext } from "react";
import { ordinalCompare } from "@murphy-frontend/common/utils";
import { useCustomer } from "./CustomerContext";
import { MurphyUser } from "@murphy-frontend/common/interfaces/IUsersApi";
import { useGetUsers } from "@murphy-frontend/common/api/Users/Queries";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import { useLocation, useNavigate } from "react-router-dom";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import PersistenceType, { IPersistenceService } from "@murphy-frontend/common/interfaces/IPersistenceService";
import { LanguageType, useUserPreferences } from "./UserPreferencesContext";
import { DbLanguages } from "@murphy-frontend/web-core/constants";
import { postLanguageId } from '@murphy-frontend/common/api/Preferences/Mutations';
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../features/CustomerPyramid/api/queryKeys";
import { mapUserViewModel } from "../../features/UsersAdmin/mappers";

export interface UserViewModel extends MurphyUser {
    mbsEnabled: boolean;
    whiteEnabled: boolean;
    traningEnabled: boolean;
    excersisesEnabled: boolean;
    guidanceEnabled: boolean;
    myMurphyEnabled: boolean;
    eventFlowsEnabled: boolean;
}

interface UserContextType {
    user: UserViewModel,
    isError: boolean,
    setUserLanguage: (language: LanguageType) => void,
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<UserViewModel | null>(null);
    const [isError, setIsError] = useState<boolean>(false);

    const auth = useAuth();
    const oidcAuth = useOidcAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { setLanguage } = useUserPreferences();
    const queryClient = useQueryClient();

    const persistenceService = useInjection<IPersistenceService>(
        PersistenceType.IPersistenceService,
    );

    const { customer } = useCustomer();

    const excludedPaths = ['/login', '/pbsresponse/', '/training/'];
    const isPathExcluded = excludedPaths.some(excludedPath => location.pathname.startsWith(excludedPath));
    const {
        isLoading: isUsersLoading, isError: isGetUsersError, data: usersData, error: getUsersError,
    } = useGetUsers(isPathExcluded ? null : customer?.Id);

    const { mutate: updateLanguageId } = postLanguageId();

    const setUserLanguage = (language: LanguageType) => {
        if (user) {
            const matchingLanguage = DbLanguages.find((p) => p.value === language);
            updateLanguageId(matchingLanguage.id, {
                onSuccess: () => {
                    setLanguage(matchingLanguage.value);
                    // invalidate queries where entities are localized
                    queryClient.invalidateQueries({ queryKey: queryKeys.all });
                    window.location.reload();
                }
            });
        } else {
            setLanguage(language);
        }
    }

    useEffect(() => {
        if (!isUsersLoading && usersData && oidcAuth.user && oidcAuth?.user?.profile?.sub) {
            const matchingUser = usersData.find((p) => ordinalCompare(p.ExternalId, oidcAuth.user.profile.sub));
            if (matchingUser) {
                const userViewModel = mapUserViewModel(matchingUser);
                setUser(userViewModel);
                const matchingLanguage = DbLanguages.find((p) => p.id === matchingUser.LanguageId);
                setLanguage(matchingLanguage.value)

                const loginRedirectHasBeenMade = persistenceService.getLoginFirstRedirectHasBeenMade();
                if (matchingUser.StartPage && !loginRedirectHasBeenMade) {
                    persistenceService.setLoginFirstRedirectHasBeenMade(true);
                    navigate(matchingUser.StartPage);
                }
                if (matchingUser.StartPage && location.pathname === '/') {
                    navigate(matchingUser.StartPage);
                }
            }
        }

        return () => {
            setUser(null);
        };
    }, [customer, isUsersLoading, usersData, oidcAuth.user, oidcAuth?.user?.profile?.sub]);

    useEffect(() => {
        if (isGetUsersError) {
            auth.signOut();
        }
    }, [isGetUsersError, getUsersError]);

    const value = {
        user,
        isError,
        setUserLanguage
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) throw new Error("UserContext Provider not found");
    return context as UserContextType;
}
