import React, { FunctionComponent, useEffect, useState } from "react";
import { IconContext } from "react-icons/lib";
import {
  RiPencilFill,
  RiInboxUnarchiveLine,
  RiInboxArchiveFill,
  RiCheckFill,
  RiCloseFill,
} from "react-icons/ri";
import ColumnGroupTable from "@murphy-frontend/web-core/components/ColumnGroupTable";
import TableCellActionDropDownButton from "@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton";
import {
  getColSizeBasedOnScreen,
  getLargeColSizeBasedOnScreen,
  getColSizeBasedOnScreenLargeNames,
} from "@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import {
  createColumnGroup,
  sortByKey,
} from "@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils";
import { dashboardTypes } from "@murphy-frontend/web-core/constants";
import { translateConstants } from "../../../common/services/translationService";

export interface DashboardsListViewModel {
  id: string;
  dashboardname: string;
  isarchived: boolean;
  link: string;
  dashboardtypeid: number;
  createddatetime: any;
  lastupdatedatetime: any;
}

interface DashboardsListProps {
  dashboards: DashboardsListViewModel[];
  onClickGoToDashboard: (dashboard: DashboardsListViewModel) => void;
  onClickEditDashboard: (dashboard: DashboardsListViewModel) => void;
  onClickArchiveDashboard: (dashboard: DashboardsListViewModel) => void;
  onClickActivateDashboard: (dashboard: DashboardsListViewModel) => void;
  translations: Record<string, string>;
  language: string;
}

const DashboardsList: FunctionComponent<DashboardsListProps> = ({
  dashboards,
  onClickGoToDashboard,
  onClickEditDashboard,
  onClickArchiveDashboard,
  onClickActivateDashboard,
  translations,
  language,
}) => {
  const { currentSize: currentScreenSize } = useWindowDimensions();
  const [columnGroups, setColumnGroups] = useState([]);
  const [currentSortingColumn, setCurrentSortingColumn] =
    useState("createddatetime");
  const [sortedAscending, setSortedAscending] = useState(false);

  const sortByColumn = (columnKey: any) => {
    setCurrentSortingColumn(columnKey);
    const newSortingOrder = !sortedAscending;
    setSortedAscending(newSortingOrder);
  };

  const generateDropDownListItems = (dashboardRow: DashboardsListViewModel) => {
    const items = [];
    items.push(
      <li key="edit" onClick={() => onClickEditDashboard(dashboardRow)}>
        <div className="vertical-aligner">
          <IconContext.Provider value={{ className: "dropdown-icon" }}>
            <RiPencilFill />
          </IconContext.Provider>
          <span>{translations.edit}</span>
        </div>
      </li>
    );

    if (dashboardRow.isarchived === false) {
      items.push(
        <li key="archive" onClick={() => onClickArchiveDashboard(dashboardRow)}>
          <div className="vertical-aligner">
            <IconContext.Provider value={{ className: "dropdown-icon" }}>
              <RiInboxArchiveFill />
            </IconContext.Provider>
            <span>{translations.archive}</span>
          </div>
        </li>
      );
    } else {
      items.push(
        <li
          key="activate"
          onClick={() => onClickActivateDashboard(dashboardRow)}
        >
          <div className="vertical-aligner">
            <IconContext.Provider value={{ className: "dropdown-icon" }}>
              <RiInboxUnarchiveLine />
            </IconContext.Provider>
            <span>{translations.activate}</span>
          </div>
        </li>
      );
    }

    return items;
  };

  const createMainColumnGroup = (
    dashboardsArray: DashboardsListViewModel[]
  ) => {
    const dashboardNameColumns = [
      {
        columnKey: "dashboardname",
        displayName: translations.name,
        isSortable: true,
        size: getLargeColSizeBasedOnScreen(currentScreenSize),
        onClickColumn: sortByColumn,
      },
    ];
    const dashboardNameRows = dashboardsArray.map((row) => [
      {
        columnKey: "dashboardname",
        id: row.id,
        rowId: row.id,
        title: row.dashboardname,
        displayValue: (
          <TableCellActionDropDownButton
            onClickRow={() => onClickGoToDashboard(row)}
            displayText={row.dashboardname}
            listItems={generateDropDownListItems(row)}
            columnSize={getLargeColSizeBasedOnScreen(currentScreenSize)}
          />
        ),
        customClass:
          (row.isarchived === false) === true
            ? "background-light-green"
            : "background-light-red",
      },
    ]);
    const mainDashboardColumnGroup = createColumnGroup(
      "DASHBOARD",
      dashboardNameColumns,
      dashboardNameRows
    );
    return mainDashboardColumnGroup;
  };

  const createDashboardInfoColumnGroup = (
    dashboardsArray: DashboardsListViewModel[]
  ) => {
    const dashboardInfoColumns = [
      {
        columnKey: "isarchived",
        displayName: translations.active,
        isSortable: true,
        size: getColSizeBasedOnScreen("sm"),
        onClickColumn: sortByColumn,
      },
      {
        columnKey: "dashboardtypeid",
        displayName: translations.typ,
        isSortable: true,
        size: getColSizeBasedOnScreen("lg"),
        onClickColumn: sortByColumn,
      },
      {
        columnKey: "createddatetime",
        displayName: translations.created,
        isSortable: true,
        size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
        onClickColumn: sortByColumn,
      },
      {
        columnKey: "lastupdatedatetime",
        displayName: translations.latestupdate,
        isSortable: true,
        size: getColSizeBasedOnScreenLargeNames(currentScreenSize),
        onClickColumn: sortByColumn,
      },
    ];
    const dashboardInfoRows = dashboardsArray.map((row) => [
      {
        columnKey: "isarchived",
        id: `archived-${row.id}`,
        rowId: row.id,
        displayValue:
          row.isarchived === false ? (
            <IconContext.Provider value={{ className: "user-table-icon" }}>
              <RiCheckFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ className: "user-table-icon" }}>
              <RiCloseFill />
            </IconContext.Provider>
          ),
        centerContent: true,
      },
      {
        columnKey: "dashboardtypeid",
        id: `type-${row.id}`,
        rowId: row.id,
        displayValue: translateConstants(language, dashboardTypes).filter(
          (dt) => dt.id === row.dashboardtypeid
        )[0].value,
      },

      {
        columnKey: "createddatetime",
        id: `createddatetime-${row.id}`,
        rowId: row.id,
        displayValue: row.createddatetime,
      },
      {
        columnKey: "lastupdatedatetime",
        id: `lastupdatedatetime-${row.id}`,
        rowId: row.id,
        displayValue: row.lastupdatedatetime,
      },
    ]);

    const dashboardInfoColumnGroup = createColumnGroup(
      "INFO",
      dashboardInfoColumns,
      dashboardInfoRows
    );
    return dashboardInfoColumnGroup;
  };

  useEffect(() => {
    if (dashboards) {
      const allColumnGroups = [];
      const dashboardCopy = [...dashboards];

      if (currentSortingColumn) {
        dashboardCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
      }

      const mainColGroup = createMainColumnGroup(dashboardCopy);

      const dashboardInfoGroup = createDashboardInfoColumnGroup(dashboardCopy);

      allColumnGroups.push(mainColGroup);
      allColumnGroups.push(dashboardInfoGroup);

      setColumnGroups(allColumnGroups);
    }
  }, [
    dashboards,
    sortedAscending,
    currentSortingColumn,
    translations,
    language,
    currentScreenSize,
  ]);

  return (
    <ColumnGroupTable
      columngroups={columnGroups}
      sortedByColumn={currentSortingColumn}
      isSortedAscending={sortedAscending}
      translations={translations}
    />
  );
};

export default DashboardsList;
