import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export interface Dashboard {
    adminlink: string,
    Name: string,
    dashboardid?: string,
    Arkiverad: boolean,
    DashboardTypeId: number,
    CreatedDateTime: Date,
    LastUpdateDateTime?: Date,
    StatusMeetingDateTime?: Date,
    StatusTypeId: number,
    CustomerId: string,
}

export interface UpdateDashboardRequest {
    DashboardId: string,
    NewName?: string,
    ActivateBoard?: boolean,
    ArchiveBoard?: boolean,
    Dashboardtype?: number,
}

export interface CreateDashboardRequest {
    CustomerId: string,
    Name: string,
    TemplateId?: string,
    Dashboardtype: number,
}

export interface DashboardMember {
    Id: string,
    Name: string,
    CustomerUserId: string,
    hasPermission: boolean,
}

export interface Template {
    TemplateId: string,
    TemplateName: string,
}

export interface EditMeetingRequest {
    DashboardId: string,
    StatusMeetingDateTime: Date,
}

export class DashboardApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(
        apiService: IApiService,
    ) {
        this.baseUrl = `api/Dashboard`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getCustomerDashboards = (customerId: string) => {
        const url = `${this.baseUrl}?customerId=${customerId}`;
        return this.apiService.callApi(url, 'GET', null);
    };

    editDashboard = (editDashboardRequest: UpdateDashboardRequest) => {
        const url = `${this.baseUrl}/${editDashboardRequest.DashboardId}`;
        return this.apiService.callApi(url, 'PUT', editDashboardRequest);
    };

    createDashboard = (createDashboardRequest: CreateDashboardRequest) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', createDashboardRequest);
    };

    getDashboardMembers = (dashboardId: string) => {
        const url = `${this.baseUrl}/${dashboardId}/Member`;
        return this.apiService.callApi(url, 'GET', null);
    };

    addMemberToDashboard = (dashboardId: string, customerUserId: string) => {
        const url = `${this.baseUrl}/${dashboardId}/Member`;
        const data = {
            CustomerUserId: customerUserId,
        };
        return this.apiService.callApi(url, 'POST', data);
    };

    removeMemberFromDashboard = (dashboardId: string, dashboardMemberId: string) => {
        const url = `${this.baseUrl}/${dashboardId}/Member/${dashboardMemberId}`;
        return this.apiService.callApi(url, 'DELETE', null);
    };

    getTemplatesByCustomerId = (customerId: string) => {
        const url = `${this.baseUrl}/Template?customerId=${customerId}`;
        return this.apiService.callApi(url, 'GET');
    };

    editMeeting = (editMeetingRequest: EditMeetingRequest) => {
        const url = `${this.baseUrl}/${editMeetingRequest.DashboardId}/StatusMeeting`;

        return this.apiService.callApi(url, 'PATCH', editMeetingRequest.StatusMeetingDateTime);
    }
};
