import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useInjection } from "../../contexts/InversifyContext";
import QueryKeys from "./QueryKeys";
import DroneEventsApiType, {
    IDroneEventsApi,
} from "../../interfaces/IDroneEventsApi";
import { toast } from "react-toastify";
import { Drone } from "./types";
import { StartEventDto } from "./models";

export function useStartEvent(customerId: string | undefined) {
    const eventsApi = useInjection<IDroneEventsApi>(
        DroneEventsApiType.IDroneEventsApi
    );
    const queryClient = useQueryClient();

    return useMutation({
        onError: (e) => toast.error(e.message),
        mutationFn: (event: StartEventDto) => eventsApi.startEvent(event),
        onSuccess: (context) => {
            queryClient.invalidateQueries({ queryKey: QueryKeys.startedDroneEvents(customerId) });
        },
    });
}

export function useCompleteEvent(customerId: string | undefined) {
    const eventsApi = useInjection<IDroneEventsApi>(
        DroneEventsApiType.IDroneEventsApi
    );
    const queryClient = useQueryClient();

    return useMutation({
        onError: (e) => toast.error(e.message),
        mutationFn: (droneId: number) => eventsApi.completeEvent({ droneId }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QueryKeys.startedDroneEvents(customerId) });
        }
    });
}