import { Products } from "@murphy-frontend/common/enums";
import { MurphyUser } from "@murphy-frontend/common/interfaces/IUsersApi";
import { UserViewModel } from "../../common/contexts/UserContext";

export const mapUserViewModel = (user: MurphyUser): UserViewModel => {
    const mbsEnabled = user.Permissions.includes(Products.MBS);
    const whiteEnabled = user.Permissions.includes(Products.White);
    const trainingEnabled = user.Permissions.includes(Products.Training);
    const excersisesEnabled = user.Permissions.includes(Products.Exercises);
    const guidanceEnabled = user.Permissions.includes(Products.Guidance);
    const myMurphyEnabled = user.Permissions.includes(Products.MurphyStandard);
    const eventFlowsEnabled = user.Permissions.includes(Products.EventFlow);
    const userViewModel: UserViewModel = {
        mbsEnabled: mbsEnabled,
        whiteEnabled: whiteEnabled,
        traningEnabled: trainingEnabled,
        excersisesEnabled: excersisesEnabled,
        guidanceEnabled: guidanceEnabled,
        myMurphyEnabled: myMurphyEnabled,
        eventFlowsEnabled: eventFlowsEnabled,
        UserId: user.UserId,
        ExternalId: user.ExternalId,
        CustomerUserId: user.CustomerUserId,
        CustomerId: user.CustomerId,
        CustomerName: user.CustomerName,
        IsSuperAdmin: user.IsSuperAdmin,
        Username: user.Username,
        Role: user.Role,
        Email: user.Email,
        DeviceToken: user.DeviceToken,
        Phonenumber: user.Phonenumber,
        Permissions: user.Permissions,
        UserTrainingSessions: user.UserTrainingSessions,
        StartPage: user.StartPage,
        LanguageId: user.LanguageId,
    };

    return userViewModel;
}