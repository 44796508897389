import React, { useState, useEffect, FunctionComponent } from 'react';
import dayjs from 'dayjs';
import MBSListTable from './MBSListTable';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService';
import { useGetSendOuts } from '../queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../../common/contexts/CustomerContext';

const MBSList: FunctionComponent = () => {
  const { customer } = useCustomer();
  const { openModal } = useModal();
  const { translations, timeZone } = useUserPreferences();

  const [error, setError] = useState(null);
  const [sendOutBatches, setSendOutBatches] = useState([]);


  const {
    isLoading: isSendOutsLoading, isError: isGetSendOutsError, data: sendOutsData, error: getSendOutsError,
  } = useGetSendOuts(customer.Id);

  const ErrorMessage = () => (<p>{error}</p>);

  const onGetSendOutBatchesSuccess = (data: any, tz) => {
    const mappedData = data.map((p) => ({
      username: p.User,
      id: p.SendOutId,
      date: dayjs.utc(p.CreatedDateTimeUtc),
      title: p.Title,
    }));

    mappedData.sort((a, b) => b.date - a.date);
    const newMappedData = mappedData.map((p) => ({
      username: p.username,
      id: p.id,
      originalDate: p.date,
      date: getConvertedDate(p.date, tz),
      title: p.title,
    }));
    setSendOutBatches(newMappedData);
  };


  useEffect(() => {
    if (!isSendOutsLoading && sendOutsData && timeZone) {
      onGetSendOutBatchesSuccess(sendOutsData, timeZone);
    }
  }, [sendOutsData, isSendOutsLoading, timeZone]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isSendOutsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>{translations.sentmbs}</h1>
      <span className="gradient" />
      <div className="pbs-grid">
        <div className="table-container">
          <MBSListTable
            translations={translations}
            rows={sendOutBatches}
            timeZone={timeZone}
          />
        </div>
      </div>
    </div>
  );
}

export default MBSList;
