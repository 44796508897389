import React, { FunctionComponent, useEffect, useState } from 'react';
import { RiPencilFill, RiDeleteBin7Fill, RiCheckFill, RiCloseFill, RiInboxArchiveFill } from 'react-icons/ri';
import { SlLocationPin } from "react-icons/sl";
import { IconContext } from 'react-icons/lib';
import ColumnGroupTable from '@murphy-frontend/web-core/components/ColumnGroupTable';
import { createColumnGroup, getColSizeBasedOnScreen, sortByKey } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from '@murphy-frontend/web-core/components/ColumnGroupTable/Models';
import { getLargeColSizeBasedOnScreen } from '@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils';
import { MurphyDroneEventDto } from '@murphy-frontend/common/interfaces/IDroneEventsApi';
import { TimeZoneType, getConvertedDate } from '@murphy-frontend/common/services/TimeService';
import dayjs from 'dayjs';
import TableCellActionDropDownButton from '@murphy-frontend/web-core/components/ColumnGroupTable/TableCellActionDropDownButton';

interface StartedEventFlowsTableProps {
    startedEventFlows: MurphyDroneEventDto[];
    onClick: (startedEventFlow: MurphyDroneEventDto) => void;
    onClickClose: (startedEventFlow: MurphyDroneEventDto) => void;
    onClickLocation: (startedEventFlow: MurphyDroneEventDto) => void;
    currentScreenSize: string;
    timeZone: TimeZoneType;
}

const StartedEventFlowsTable: FunctionComponent<StartedEventFlowsTableProps> = ({
    startedEventFlows,
    currentScreenSize,
    timeZone,
    onClick,
    onClickClose,
    onClickLocation
}) => {
    const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
    const [maxColumns, setMaxColumns] = useState<number>(0);
    const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('CreatedUTC');
    const [sortedAscending, setSortedAscending] = useState<boolean>(false);

    useEffect(() => {
        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const generateDropDownListItems = (startedEventFlow: MurphyDroneEventDto) => {
            const ddlItems = [];

            ddlItems.push(
                <li key="close" onClick={() => onClickClose(startedEventFlow)}>
                    <div className="vertical-aligner">
                        <IconContext.Provider value={{ className: 'dropdown-icon' }}>
                            <RiInboxArchiveFill />
                        </IconContext.Provider>
                        <span>Close</span>
                    </div>
                </li>
            );

            return ddlItems;
        };

        const createCustomerGroupColumnGroup = (startedEventFlowsArray: MurphyDroneEventDto[]) => {
            const customerGroupNameColumns: ColumnGroupColumnCell[] = [
                {
                    columnKey: 'Name',
                    displayName: "Name",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'CreatedUTC',
                    displayName: "Date",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'CreatedByUserEmail',
                    displayName: "Created By",
                    isSortable: true,
                    size: getLargeColSizeBasedOnScreen(currentScreenSize),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'Open',
                    displayName: "Open",
                    isSortable: true,
                    size: getColSizeBasedOnScreen('sm'),
                    onClickColumn: sortByColumn,
                },
                {
                    columnKey: 'Location',
                    displayName: "Location",
                    isSortable: false,
                    size: getColSizeBasedOnScreen('sm'),
                }
            ];

            const customerGroupNameRows: ColumnGroupRowCell[][] = startedEventFlowsArray.map((row) => [
                {
                    id: `name-${row.Id}`,
                    rowId: row.Id,
                    title: row.Name,
                    displayValue:
                        (
                            <TableCellActionDropDownButton
                                displayText={row.Name}
                                listItems={generateDropDownListItems(row)}
                                columnSize="column-large"
                                onClickRow={() => onClick(row)}
                            />
                        ),
                    columnKey: 'Name',
                },
                {
                    id: `date-${row.Id}`,
                    rowId: row.Id,
                    title: row.CreatedUTC,
                    displayValue: getConvertedDate(dayjs.utc(row.CreatedUTC), timeZone),
                    columnKey: 'CreatedUTC',
                },
                {
                    id: `email-${row.Id}`,
                    rowId: row.Id,
                    title: row.CreatedByUserEmail,
                    displayValue: row.CreatedByUserEmail,
                    columnKey: 'CreatedByUserEmail',
                },
                {
                    columnKey: 'Open',
                    id: `open-${row.Id}`,
                    rowId: row.Id,
                    displayValue: row.Open === true
                        ? (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCheckFill />
                            </IconContext.Provider>
                        )
                        : (
                            <IconContext.Provider value={{ className: 'user-table-icon' }}>
                                <RiCloseFill />
                            </IconContext.Provider>
                        ),
                    centerContent: true,
                },
                {
                    columnKey: 'Location',
                    id: `location-${row.Id}`,
                    rowId: row.Id,
                    displayValue: row.Latitude ? (
                        <IconContext.Provider value={{ className: 'user-table-icon' }}>
                            <SlLocationPin onClick={() => onClickLocation(row)} />
                        </IconContext.Provider>
                    ) : null,
                    centerContent: true,
                }
            ]);

            const customerGroupNameColumnGroup = createColumnGroup(
                "",
                customerGroupNameColumns,
                customerGroupNameRows
            );

            return customerGroupNameColumnGroup;
        };

        if (startedEventFlows) {
            const allColumnGroups = [];
            const StartedEventFlowsCopy = [...startedEventFlows];

            if (currentSortingColumn) {
                StartedEventFlowsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
            }

            const customerGroupNameColGroup = createCustomerGroupColumnGroup(StartedEventFlowsCopy);
            allColumnGroups.push(customerGroupNameColGroup);

            setMaxColumns(customerGroupNameColGroup.columns.length);
            setColumnGroups(allColumnGroups);
        }
    }, [startedEventFlows, sortedAscending, currentSortingColumn, currentScreenSize, timeZone]);

    return (
        <ColumnGroupTable
            maxColumns={maxColumns}
            columngroups={columnGroups}
            sortedByColumn={currentSortingColumn}
            isSortedAscending={sortedAscending}
        />
    );
};

export default StartedEventFlowsTable;
